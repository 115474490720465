var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { AuthTypes } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var MyMixin = {
  methods: {
    hello() {
      return "hello from mixin!";
    }
  }
};
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list" }, [_c("a-row", { staticClass: "list__title pt-2 pb-4" }, [_c("a-col", { staticClass: "background--white", attrs: { "span": 16 } }, [_c("h2", { staticClass: "mb-0 neutral-10--text" }, [_vm._v("Shipment Status")])]), _c("a-col", { staticClass: "background--white", attrs: { "span": 8 } }, [_c("div", { staticClass: "btn" }, [_c("a-button", { attrs: { "float": "right", "type": "primary" }, on: { "click": _vm.exportFile } }, [_vm._v(" Export to Excel ")])], 1)])], 1), _c("div", { staticClass: "ml-5 mt-3" }, [_c("div", { staticClass: "search" }), _c("a-input-search", { staticStyle: { "width": "300px" }, attrs: { "placeholder": " Search by Ship No.and Purch Ord." }, on: { "change": _vm.onShipNoPurchOrderSearch } }), _c("div", { staticClass: "drop pr-5" }, [[_vm.dynamicColumns ? _c("a-dropdown", { staticClass: "ml-2", attrs: { "trigger": ["click"] }, scopedSlots: _vm._u([{ key: "overlay", fn: function() {
    return [_c("a-menu", { staticClass: "dynamic-columns" }, [_vm._l(_vm.checkedList, function(index2) {
      return _c("a-menu-item", { key: index2.id, attrs: { "disabled": index2.isFixed } }, [_c("a-checkbox", { attrs: { "disabled": index2.isFixed, "value": index2.id, "default-checked": index2.isVisible }, on: { "change": function($event) {
        return _vm.onChange($event);
      } } }), _vm._v(" " + _vm._s(index2.columnName) + " ")], 1);
    }), _c("a-menu-item", { staticClass: "dynamic-columns__buttons" }, [_c("a-button", { on: { "click": _vm.handleCancelClick } }, [_vm._v("Cancel")]), _c("a-button", { staticClass: "dynamic-columns__buttons--ok", attrs: { "type": "primary" }, on: { "click": _vm.handleApplyColumns } }, [_vm._v(" OK ")])], 1)], 2)];
  }, proxy: true }], null, false, 2442254127), model: { value: _vm.visibleDropdown, callback: function($$v) {
    _vm.visibleDropdown = $$v;
  }, expression: "visibleDropdown" } }, [_c("a-button", { attrs: { "icon": "bars" }, on: { "click": _vm.handleChecked } }, [_vm._v(" Hide/Show Columns ")])], 1) : _vm._e()]], 2)], 1), _c("div", { staticClass: "list__table p-5" }, [_c("div", { staticClass: "mb-5 mt-3", staticStyle: { "background-color": "white", "padding": "10px", "border": "1px solid rgb(162 179 195 / 65%)", "overflow-x": "scroll" } }, [_vm._m(0), _c("br"), _c("div", { staticClass: "list__basic-filters d-flex justify-content-between mb-3" }, [_c("div", { staticClass: "d-flex justtify-content-between align-items-end" }, [_c("div", { staticClass: "filter ml-2 mr-4" }, [_c("select-filter", { key: "country", attrs: { "data-source": _vm.arrCountry, "source": "code", "show-search": "", "label": "Country", "source-label": "name", "rules": "required", "value": { value: _vm.defCountry }, "span": 24, "placeholder": "Country" }, on: { "change": function($event) {
    return _vm.onFilterChange($event, "country");
  } } })], 1), _c("div", { staticClass: "filter mr-4" }, [_c("select-filter", { key: "category", attrs: { "data-source": _vm.listCategory, "source": "categoryName", "mode": "multiple", "show-search": "", "label": "Category", "source-label": "categoryName", "rules": "required", "value": { value: _vm.defCategory }, "span": 24, "placeholder": "Category" }, on: { "change": function($event) {
    return _vm.onFilterChange($event, "category");
  } } })], 1), _c("div", { staticClass: "filter mr-4" }, [_c("select-filter", { key: "portfolio", attrs: { "data-source": _vm.listPortfolio, "mode": "multiple", "source": "portfolioName", "show-search": "", "source-label": "portfolioName", "rules": "required", "label": "Portfolio", "span": 24, "placeholder": "Portfolio", "value": { value: _vm.defPortfolio } }, on: { "change": function($event) {
    return _vm.onFilterChange($event, "portfolio");
  } } })], 1), _c("div", { staticClass: "filter mr-4" }, [_c("select-filter", { key: "vendName", attrs: { "source": "vendName", "mode": "multiple", "show-search": "", "label": "Vendor Name", "source-label": "vendName", "placeholder": "Vendor Name", "data-source": _vm.listVenderName, "value": { value: _vm.defVenderName }, "span": 24 }, on: { "change": function($event) {
    return _vm.onFilterChange($event, "vendName");
  } } })], 1), _c("div", { staticClass: "filter mr-4" }, [_c("select-filter", { key: "logic", attrs: { "source": "logic", "show-search": "", "label": "Logic", "source-label": "logic", "placeholder": "Logic", "data-source": _vm.listLogic, "value": { value: _vm.defLogic }, "span": 24 }, on: { "change": function($event) {
    return _vm.onFilterChange($event, "logic");
  } } })], 1), _c("div", { staticClass: "filter mr-4" }, [_vm._v(" Max of Invoice Date "), _c("a-tree-select", { staticStyle: { "width": "100%" }, attrs: { "filter-tree-node": true, "tree-checkable": "", "allow-clear": "", "multiple": "", "placeholder": "Please select max of invoice date" }, on: { "change": function($event) {
    return _vm.onFilterChange($event, "maxOfInvoiceDate");
  } }, model: { value: _vm.defMaxOfInvoiceDate, callback: function($$v) {
    _vm.defMaxOfInvoiceDate = $$v;
  }, expression: "defMaxOfInvoiceDate" } }, _vm._l(_vm.listMaxOfInvoiceDate, function(option) {
    return _c("a-tree-select-node", { key: option.year, attrs: { "value": option.year, "title": option.year } }, _vm._l(option.monthOfYear, function(opt) {
      return _c("a-tree-select-node", { key: option.year + "-" + opt.month, attrs: { "value": option.year + "-" + opt.month, "title": opt.month } });
    }), 1);
  }), 1)], 1), _c("div", { staticClass: "filter mr-4" }, [_c("select-filter", { key: "isClosed", attrs: { "source": "val", "label": "Marked to Close", "source-label": "isClosed", "placeholder": "Marked to Close", "data-source": _vm.listMarkedToClose, "value": { value: _vm.defMarkToClose } }, on: { "change": function($event) {
    return _vm.onFilterChange($event, "isClosed");
  } } })], 1), _c("div", { staticClass: "filter mr-2" }, [_c("a-button", { attrs: { "icon": "close-circle" }, on: { "click": _vm.onClearFiltersClick } }, [_vm._v(" Clear filters ")])], 1)])])]), _c("a-table", { attrs: { "bordered": "", "loading": _vm.isLoading, "columns": _vm.columnsActive, "data-source": _vm.resourceList, "pagination": _vm.pagination, "row-key": "id", "indent-size": -15, "scroll": { x: 2e3, y: 500 } }, on: { "change": _vm.onTableChange }, scopedSlots: _vm._u([{ key: "MarkToClose", fn: function(text, record) {
    return _c("a", {}, [[_c("div", [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Mark to Close")];
    }, proxy: true }], null, true) }, [_c("a-switch", { staticClass: "ml-3", on: { "click": function($event) {
      return _vm.markToClose(record);
    } }, model: { value: record.isClosed, callback: function($$v) {
      _vm.$set(record, "isClosed", $$v);
    }, expression: "record.isClosed" } })], 1)], 1)]], 2);
  } }]) })], 1)], 1);
};
var staticRenderFns$1 = [function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "searchflt" }, [_c("label", [_vm._v(" Filters ")])]);
}];
render$1._withStripped = true;
var ListShipmentClosureStatus_vue_vue_type_style_index_0_scoped_true_lang = "";
var defaultCheckedList = [];
var columnsActive = [];
const __vue2_script$1 = {
  name: "ListShipmentClosureStatus",
  inject: ["apiUrl", "crud", "resourceName", "can"],
  mixins: [MyMixin],
  data() {
    const columns = [
      {
        title: "Country",
        dataIndex: "country",
        key: "country",
        sorter: true,
        disabled: true,
        checked: true,
        hidden: false,
        width: 100,
        class: "left-align"
      },
      {
        title: "Portfolio",
        dataIndex: "portfolio",
        key: "portfolio",
        sorter: true,
        disabled: true,
        checked: true,
        width: 120,
        hidden: false,
        class: "left-align"
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        sorter: true,
        disabled: true,
        checked: true,
        width: 120,
        hidden: false,
        class: "left-align"
      },
      {
        title: "Ship No",
        dataIndex: "shipNo",
        key: "shipNo",
        sorter: true,
        disabled: true,
        checked: true,
        width: 120,
        hidden: false,
        class: "left-align"
      },
      {
        title: "Purch Order",
        dataIndex: "purchOrd",
        key: "purchOrd",
        sorter: true,
        disabled: true,
        checked: true,
        width: 120,
        hidden: false,
        class: "left-align"
      },
      {
        title: "Vendor",
        dataIndex: "vendor",
        key: "vendor",
        disabled: true,
        checked: true,
        sorter: true,
        width: 120,
        hidden: false,
        class: "left-align"
      },
      {
        title: "Vendor Name",
        dataIndex: "vendName",
        key: "vendName",
        disabled: true,
        checked: true,
        sorter: true,
        width: 250,
        hidden: false,
        class: "left-align"
      },
      {
        title: "Marked to Close",
        dataIndex: "isClosed",
        key: "isClosed",
        disabled: true,
        checked: true,
        sorter: true,
        width: 150,
        hidden: false,
        scopedSlots: { customRender: "MarkToClose" }
      },
      {
        title: "Estimated Cost(PO)",
        hidden: false,
        children: [
          {
            title: "MA",
            dataIndex: "estimated_MA",
            key: "estimated_MA",
            sorter: true,
            disabled: true,
            checked: true,
            width: 150,
            hidden: false,
            customRender: (value) => {
              return {
                children: this.format(value)
              };
            }
          },
          {
            title: "FR",
            dataIndex: "estimated_FR",
            key: "estimated_FR",
            sorter: true,
            disabled: true,
            checked: true,
            width: 150,
            hidden: false,
            customRender: (value) => {
              return {
                children: this.format(value)
              };
            }
          },
          {
            title: "LC",
            dataIndex: "estimated_LC",
            key: "estimated_LC",
            sorter: true,
            disabled: true,
            checked: true,
            width: 150,
            hidden: false,
            customRender: (value) => {
              return {
                children: this.format(value)
              };
            }
          },
          {
            title: "LF",
            dataIndex: "estimated_LF",
            key: "estimated_LF",
            sorter: true,
            disabled: true,
            checked: true,
            width: 150,
            hidden: false,
            customRender: (value) => {
              return {
                children: this.format(value)
              };
            }
          },
          {
            title: "Other",
            dataIndex: "estimated_Other",
            key: "estimated_Other",
            sorter: true,
            disabled: true,
            checked: true,
            width: 150,
            hidden: false,
            customRender: (value) => {
              return {
                children: this.format(value)
              };
            }
          }
        ]
      },
      {
        title: "Actual Cost(Invoiced)",
        disabled: true,
        checked: true,
        hidden: false,
        children: [
          {
            title: "MA",
            dataIndex: "actual_Cost_MA",
            key: "actual_Cost_MA",
            sorter: true,
            disabled: true,
            checked: true,
            hidden: false,
            width: 150,
            customRender: (value) => {
              return {
                children: this.format(value)
              };
            }
          },
          {
            title: "FR",
            dataIndex: "actual_Cost_FR",
            key: "actual_Cost_FR",
            sorter: true,
            disabled: true,
            checked: true,
            hidden: false,
            width: 150,
            customRender: (value) => {
              return {
                children: this.format(value)
              };
            }
          },
          {
            title: "LC",
            dataIndex: "actual_Cost_LC",
            key: "actual_Cost_LC",
            sorter: true,
            disabled: true,
            checked: true,
            hidden: false,
            width: 150,
            customRender: (value) => {
              return {
                children: this.format(value)
              };
            }
          },
          {
            title: "LF",
            dataIndex: "actual_Cost_LF",
            key: "actual_Cost_LF",
            sorter: true,
            disabled: true,
            checked: true,
            hidden: false,
            width: 150,
            customRender: (value) => {
              return {
                children: this.format(value)
              };
            }
          },
          {
            title: "Other",
            dataIndex: "actual_Other",
            key: "actual_Other",
            sorter: true,
            disabled: true,
            checked: true,
            hidden: false,
            width: 150,
            customRender: (value) => {
              return {
                children: this.format(value)
              };
            }
          }
        ]
      },
      {
        title: "$Variance",
        width: 250,
        sorter: true,
        disabled: true,
        checked: true,
        hidden: false,
        children: [
          {
            title: "MA",
            dataIndex: "variance_MA",
            key: "variance_MA",
            sorter: true,
            disabled: true,
            checked: true,
            width: 150,
            hidden: false,
            customRender: (value) => {
              return {
                children: this.format(value)
              };
            }
          },
          {
            title: "FR",
            dataIndex: "variance_FR",
            key: "variance_FR",
            sorter: true,
            disabled: true,
            checked: true,
            hidden: false,
            width: 150,
            customRender: (value) => {
              return {
                children: this.format(value)
              };
            }
          },
          {
            title: "LC",
            dataIndex: "variance_LC",
            key: "variance_LC",
            sorter: true,
            disabled: true,
            checked: true,
            hidden: false,
            width: 150,
            customRender: (value) => {
              return {
                children: this.format(value)
              };
            }
          },
          {
            title: "LF",
            dataIndex: "variance_LF",
            key: "variance_LF",
            sorter: true,
            disabled: true,
            checked: true,
            hidden: false,
            width: 150,
            customRender: (value) => {
              return {
                children: this.format(value)
              };
            }
          },
          {
            title: "Other",
            dataIndex: "variance_Other",
            key: "variance_Other",
            sorter: true,
            disabled: true,
            checked: true,
            hidden: false,
            width: 150,
            customRender: (value) => {
              return {
                children: this.format(value)
              };
            }
          }
        ]
      },
      {
        title: "Ship Type",
        dataIndex: "shipType",
        key: "shipType",
        sorter: true,
        hidden: false,
        width: 150
      },
      {
        title: "Max of Invoice Date",
        dataIndex: "max_Of_Invoice_Date",
        key: "max_Of_Invoice_Date",
        sorter: true,
        hidden: false,
        width: 250
      },
      {
        title: "Orderd Qty2",
        dataIndex: "ordered_Qty_2",
        key: "ordered_Qty_2",
        sorter: true,
        hidden: false,
        width: 150,
        customRender: (value) => {
          return {
            children: this.format(value)
          };
        }
      },
      {
        title: "Recvd Qty2",
        dataIndex: "recvd_Qty_2",
        key: "recvd_Qty_2",
        sorter: true,
        hidden: false,
        width: 150,
        customRender: (value) => {
          return {
            children: this.format(value)
          };
        }
      },
      {
        title: "Costed Qty2",
        dataIndex: "costed_Qty_2",
        key: "costed_Qty_2",
        sorter: true,
        width: 150,
        customRender: (value) => {
          return {
            children: this.format(value)
          };
        }
      },
      {
        title: "Sum of Trans Currency Est Cost",
        dataIndex: "sum_Of_Trans_Currency_Est_Cost",
        key: "sum_Of_Trans_Currency_Est_Cost",
        sorter: true,
        hidden: false,
        width: 150,
        customRender: (value) => {
          return {
            children: this.format(value)
          };
        }
      },
      {
        title: "Sum of Trans Currency Act Cost",
        dataIndex: "sum_Of_Trans_Currency_Act_Cost",
        key: "sum_Of_Trans_Currency_Act_Cost",
        sorter: true,
        width: 150,
        hidden: false,
        customRender: (value) => {
          return {
            children: this.format(value)
          };
        }
      },
      {
        title: "Sum of Base Currency Est Cost",
        dataIndex: "sum_Of_Base_Currency_Est_Cost",
        key: "sum_Of_Base_Currency_Est_Cost",
        sorter: true,
        width: 150,
        hidden: false,
        customRender: (value) => {
          return {
            children: this.format(value)
          };
        }
      },
      {
        title: "Sum of Base Currency Act Cost",
        dataIndex: "sum_Of_Base_Currency_Act_Cost",
        key: "sum_Of_Base_Currency_Act_Cost",
        sorter: true,
        width: 150,
        customRender: (value) => {
          return {
            children: this.format(value)
          };
        }
      },
      {
        title: "Sum of Base Currency Recpt Accrual",
        dataIndex: "sum_Of_Base_Currency_Recpt_Accurual",
        key: "sum_Of_Base_Currency_Recpt_Accurual",
        sorter: true,
        width: 150,
        hidden: false,
        customRender: (value) => {
          return {
            children: this.format(value)
          };
        }
      },
      {
        title: "Sum of Est PPV",
        dataIndex: "sum_Of_Est_PPV",
        sorter: true,
        key: "sum_Of_Est_PPV",
        width: 150,
        hidden: false,
        customRender: (value) => {
          return {
            children: this.format(value)
          };
        }
      },
      {
        title: "Sum of Act PPV",
        dataIndex: "sum_Of_Act_PPV",
        key: "sum_Of_Act_PPV",
        sorter: true,
        width: 150,
        hidden: false,
        customRender: (value) => {
          return {
            children: this.format(value)
          };
        }
      },
      {
        title: "Sum of FX PPV",
        dataIndex: "sum_Of_FX_PPV",
        key: "sum_Of_FX_PPV",
        sorter: true,
        width: 150,
        hidden: false,
        customRender: (value) => {
          return {
            children: this.format(value)
          };
        }
      },
      {
        title: "Close Logic",
        width: 250,
        sorter: true,
        disabled: true,
        checked: true,
        hidden: false,
        children: [
          {
            title: "GR Match %",
            dataIndex: "logic_GR_Match",
            key: "logic_GR_Match",
            sorter: true,
            disabled: true,
            checked: true,
            hidden: false,
            width: 150
          },
          {
            title: "MA Match %",
            dataIndex: "logic_MA_Match",
            key: "logic_MA_Match",
            sorter: true,
            disabled: true,
            checked: true,
            hidden: false,
            width: 150
          },
          {
            title: "Rest of Cost %",
            dataIndex: "logic_Rest_Of_Cost",
            key: "logic_Rest_Of_Cost",
            sorter: true,
            disabled: true,
            checked: true,
            hidden: false,
            width: 150
          },
          {
            title: "GR Rule",
            dataIndex: "gR_Rule",
            key: "gR_Rule",
            sorter: true,
            disabled: true,
            checked: true,
            hidden: false,
            width: 150
          },
          {
            title: "MA Rule",
            dataIndex: "mA_Rule",
            key: "mA_Rule",
            sorter: true,
            disabled: true,
            checked: true,
            hidden: false,
            width: 150
          },
          {
            title: "Rest of Cost Rules",
            dataIndex: "rest_of_Cost_Rule",
            key: "rest_of_Cost_Rule",
            sorter: true,
            disabled: true,
            checked: true,
            hidden: false,
            width: 150
          },
          {
            title: "Other",
            dataIndex: "other",
            key: "other",
            sorter: true,
            disabled: true,
            checked: true,
            hidden: false,
            width: 150
          },
          {
            title: "Logic",
            dataIndex: "logic",
            key: "logic",
            sorter: true,
            disabled: true,
            checked: true,
            hidden: false,
            width: 150
          }
        ]
      }
    ];
    const countries = [];
    defaultCheckedList = [];
    return {
      pageSizeOptions: ["10", "20", "50", "100"],
      hidePagination: false,
      hasSizeChanger: true,
      columns,
      countries,
      listChecklist: [],
      arrCountry: [],
      listCategory: [],
      listPortfolio: [],
      listLogic: [],
      listMaxOfInvoiceDate: [],
      listMarkedToClose: [],
      listVenderName: [],
      default: {},
      arrMonths: [],
      columnsActive,
      defCountry: ["All"],
      defCategory: void 0,
      defVenderName: void 0,
      defLogic: ["All"],
      defMarkToClose: [-1],
      defPortfolio: void 0,
      defMaxOfInvoiceDate: void 0,
      userID: 0,
      visibleDropdown: false,
      checkedList: [],
      dynamicColumns: true,
      plainOptions: [],
      urlTrackingColumn: `${this.apiUrl}/shipment-closure/column-master-data/columnmaster-with-user-data?id=`,
      fields: []
    };
  },
  computed: {
    pagination() {
      if (this.hidePagination)
        return false;
      const { page, count, pageSize } = this.crud.getPagination();
      return {
        current: page,
        showSizeChanger: this.hasSizeChanger,
        showTotal: this.showTotal,
        pageSizeOptions: this.pageSizeOptions,
        total: count,
        pageSize
      };
    },
    resourceList() {
      this.setColumnActive();
      return this.crud.getList();
    },
    isLoading() {
      return this.$store.state[this.resourceName].isFetchingList;
    },
    userInfo() {
      const { namespace, AUTH_GET_USER } = AuthTypes;
      return this.$store.getters[`${namespace}/${AUTH_GET_USER}`];
    }
  },
  async created() {
    const { data } = await this.axios.get(`${this.apiUrl}/shipment-closure/category-data?fe=((IsInactive Eq 'false'))`);
    this.listCategory = data.results;
    let dataportfolio = await this.axios.get(`${this.apiUrl}/shipment-closure/portfolio-data?fe=((IsInactive Eq 'false'))`);
    this.listPortfolio = dataportfolio.data.results;
    let datacountry = await this.axios.get(`${this.apiUrl}/shipment-closure/country-data`);
    this.arrCountry = datacountry.data.results;
    this.default = {
      id: 0,
      name: "All",
      code: "All"
    };
    this.arrCountry.splice(0, 0, this.default);
    let dataVenderName = await this.axios.get(`${this.apiUrl}/shipment-closure/shipment-data/vendor-data`);
    this.listVenderName = dataVenderName.data.results;
    let dataLogic = await this.axios.get(`${this.apiUrl}/shipment-closure/shipment-data/logic-data`);
    this.listLogic = dataLogic.data.results;
    this.default = {
      logic: "All"
    };
    this.listLogic.splice(0, 0, this.default);
    this.listMarkedToClose = [
      { val: -1, isClosed: "All" },
      { val: 0, isClosed: "Open" },
      { val: 1, isClosed: "Close" }
    ];
    let dataMaxOfInvoiceDate = await this.axios.get(`${this.apiUrl}/shipment-closure/shipment-data/maxofinvoicedate-data`);
    this.listMaxOfInvoiceDate = dataMaxOfInvoiceDate.data.results;
    this.listMaxOfInvoiceDate.map((i) => i.monthOfYear.map((i2) => i2.isChecked = false));
    this.userID = this.userInfo.id;
    if (this.dynamicColumns) {
      this.getCheckedListColumns();
    }
    this.arrMonths = [
      { intMonth: 1, Month: "January" },
      { intMonth: 2, Month: "February" },
      { intMonth: 3, Month: "March" },
      { intMonth: 4, Month: "April" },
      { intMonth: 5, Month: "May" },
      { intMonth: 6, Month: "June" },
      { intMonth: 7, Month: "July" },
      { intMonth: 8, Month: "August" },
      { intMonth: 9, Month: "Sepetember" },
      { intMonth: 10, Month: "October" },
      { intMonth: 11, Month: "November" },
      { intMonth: 12, Month: "December" }
    ];
    this.crud.deleteFilter("IsInactive");
    this.crud.fetchList();
  },
  methods: {
    showTotal(total, range) {
      return `Viewing ${range[0]}-${range[1]} of ${total}`;
    },
    setColumnActive() {
      this.getColumns();
      this.columnsActive = this.columns.filter((item) => item.hidden == false);
      for (var i = 0; i < this.columnsActive.length; i++) {
        if (this.columnsActive[i].children != void 0 && this.columnsActive[i].children.length > 0) {
          for (var j = 0; j <= this.columnsActive[i].children.length - 1; j++) {
            if (this.columnsActive[i].children[j].hidden == true) {
              this.columnsActive[i].children.splice(j, 1);
              j--;
            }
          }
        }
      }
    },
    async getColumns() {
      let dataColunmMaster = await this.axios.get(this.urlTrackingColumn + this.userID);
      let listChecklist = dataColunmMaster.data;
      let index2 = 0;
      for (index2 = 0; index2 <= this.columns.length - 1; index2++) {
        if (this.columns[index2].children != void 0 && this.columns[index2].children.length > 0) {
          let childIndex = 0;
          let count = 0;
          for (childIndex = 0; childIndex <= this.columns[index2].children.length - 1; childIndex++) {
            let objColumn = listChecklist.filter((item) => item.dbColumnName.toLowerCase() == this.columns[index2].children[childIndex].key.toLowerCase());
            let isVisible = objColumn[0] != void 0 ? objColumn[0].isVisible : false;
            if (isVisible == false) {
              count = count + 1;
            }
            this.columns[index2].children[childIndex].hidden = !isVisible;
          }
          if (count == this.columns[index2].children.length) {
            this.columns[index2].hidden = true;
          } else {
            this.columns[index2].hidden = false;
          }
        } else {
          let objColumn = listChecklist.filter((item) => item.dbColumnName.toLowerCase() == this.columns[index2].key.toLowerCase());
          let isVisible = objColumn[0] != void 0 ? objColumn[0].isVisible : false;
          this.columns[index2].hidden = !isVisible;
        }
      }
    },
    onFilterChange(value, key) {
      this.crud.deleteFilter("IsInactive");
      let filterVal = value.value != void 0 ? value.value : "";
      let operator = value.operator != void 0 ? value.operator : key == "isClosed" || key == "country" ? "Eq" : "In";
      if (key == "maxOfInvoiceDate") {
        this.crud.setFilter("max_Of_Invoice_Date", {
          operator: "In",
          value: value.join(",")
        });
      } else {
        if (filterVal.toString().length > 0) {
          key == "country" ? this.defCountry = filterVal : key == "category" ? this.defCategory = filterVal : key == "portfolio" ? this.defPortfolio = filterVal : key == "vendName" ? this.defVenderName = filterVal : key == "logic" ? this.defLogic = filterVal : key == "isClosed" ? this.defMarkToClose = filterVal : "";
          this.storeValue(filterVal, key);
        }
        if (filterVal != "All" && filterVal != void 0 && filterVal != -1 && filterVal != 0) {
          this.crud.setFilter(key, {
            operator,
            value: filterVal
          });
        } else if (filterVal == 0 && filterVal.toString().length > 0) {
          this.crud.setFilter(key, {
            operator,
            value: "0"
          });
        } else {
          this.crud.deleteFilter(key);
          this.crud.fetchList();
          return;
        }
      }
      return this.crud.fetchList();
    },
    onClearFiltersClick() {
      this.defCountry = ["All"];
      this.defCategory = void 0;
      this.defPortfolio = void 0;
      this.defVenderName = void 0;
      this.defLogic = ["All"];
      this.defMarkToClose = ["All"];
      this.defMaxOfInvoiceDate = void 0;
      this.listMaxOfInvoiceDate.map((i) => i.monthOfYear.map((i2) => i2.isChecked = false));
      this.crud.clearFilters();
      this.crud.deleteQueryStrings();
      this.crud.deleteFilter("IsInactive");
      this.crud.fetchList();
    },
    onTableChange(pagination, filters, sorter) {
      const { current, pageSize } = pagination;
      this.crud.setPagination(__spreadProps(__spreadValues({}, this.crud.getPagination()), {
        page: current,
        pageSize
      }));
      const { columnKey, order } = sorter;
      this.crud.setSorter({ columnKey, order });
      this.crud.fetchList();
    },
    exportFile() {
      const file = `shipment_closure_status_${this.$moment().format("DD_MM_YYYY")}.csv`;
      this.axios.get(this.apiUrl + `/shipment-closure/shipment-data/export-data?` + this.crud.getFilterQuery()).then((res) => {
        let fileName = file;
        this.data = {
          data: res.data,
          fileName,
          isExcel: false,
          contentType: res.headers["content-type"]
        };
        this.stringToCsvFile(fileName, res.data, false, res.headers["content-type"]);
        this.isDownload = false;
      }).catch((err) => {
        this.showErrorMsg(err);
      });
    },
    handleCancelClick() {
      this.visibleDropdown = false;
    },
    handleChecked() {
      this.getCheckedListColumns();
    },
    getCheckedListColumns() {
      this.axios.get(this.urlTrackingColumn + this.userID).then((response) => {
        this.checkedList = response.data ? response.data : defaultCheckedList;
        console.log(this.checkedList);
      }).catch((err) => console.error(err));
    },
    handleApplyColumns() {
      let columIDs = [];
      for (var i = 0; i < this.checkedList.length - 1; i++) {
        columIDs.push({
          columnID: this.checkedList[i].id,
          isVisible: this.checkedList[i].isVisible
        });
      }
      this.axios.post(this.apiUrl + `/shipment-closure/user-transaction-data`, {
        userID: this.userID,
        columnIDs: columIDs
      }, { headers: { "Content-Type": "application/json" } }).then(() => {
        this.showNotification("success", "Shipment data columns Updated");
        this.visibleDropdown = false;
        window.location.reload(true);
      });
    },
    showNotification(type, message) {
      this.$notification[type]({ message });
    },
    showErrorMsg(err) {
      this.$notification["error"]({
        message: err
      });
    },
    onChange(value) {
      let columnID = value.target.value;
      let isChecked = value.target.checked;
      let objIndex = this.checkedList.findIndex((obj) => obj.id == columnID);
      this.checkedList[objIndex].isVisible = isChecked;
    },
    stringToCsvFile(fileName, data, isExcel = false, type = "") {
      let hiddenElement = document.createElement("a");
      if (isExcel) {
        let blob = new Blob([data], {
          type
        });
        hiddenElement.href = URL.createObjectURL(blob);
      } else {
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(data);
      }
      hiddenElement.target = "_blank";
      hiddenElement.download = fileName;
      hiddenElement.click();
    },
    storeValue(value, resourceKey, resourcePath) {
      this.editForm = __spreadProps(__spreadValues({}, this.editForm), {
        [resourceKey]: { value, resourcePath }
      });
      this.crud.updateEntity("editForm", { resourceKey, value, resourcePath });
    },
    getIntMonth(month) {
      return this.arrMonths.findIndex((obj) => obj.Month == month) + 1;
    },
    markToClose(record) {
      let countryID = this.arrCountry.findIndex((obj) => obj.code == record.country);
      this.axios.post(this.apiUrl + `/shipment-closure/mark-to-close-data`, {
        shipNo: record.shipNo,
        purchOrd: record.purchOrd,
        isClosed: record.isClosed == true ? 1 : 0,
        country: countryID
      }, { headers: { "Content-Type": "application/json" } }).then(() => {
        this.showNotification("success", record.isClosed == true ? "Record is marked as a close." : "Record is marked as a open.");
        this.crud.fetchList();
      }).catch((err) => this.showErrorMsg(err));
    },
    format(value) {
      return parseFloat(value).toFixed(1);
    },
    onShipNoPurchOrderSearch(value) {
      if (value.currentTarget.value === "") {
        this.crud.deleteFilter("ShipNoPurchOrder");
        this.crud.fetchList();
        return;
      }
      this.crud.setFilter("ShipNoPurchOrder", {
        operator: "In",
        value: value.currentTarget.value
      });
      this.crud.getFilterQuery();
      this.crud.fetchList();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "70289d95", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
__component__$1.options.__file = "src/views/ShipmentClosureStatus/ListShipmentClosureStatus/ListShipmentClosureStatus.vue";
var ListShipmentClosureStatus = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-shipment-closure-status" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "shipment-closure.shipment-data.shipment-data", "api-url": _vm.apiUrl } }, [_c("list-shipment-closure-status")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
render._withStripped = true;
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListShipmentClosureStatus
  },
  data() {
    return {
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Shipment closure Status",
          path: "/shipment-closure/shipment-closure-status"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
__component__.options.__file = "src/views/ShipmentClosureStatus/ListShipmentClosureStatus/index.vue";
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
